import { useState } from "react";

function Navbar() {

const [menu, openMenu] = useState(true)
function open(){
    openMenu(!menu)
}
    return (
        <div className="bg-gray-800 text-slate-50 cursor-pointer text-lg drop-shadow-[0_35px_35px_rgba(158, 22, 242, 0.5)]">
            <div className=" h-14 flex flex-row justify-between">
                <div className="flex items-center  self-center pl-16">
                    <span className="hover:text-slate-300 text-2xl ">Logo</span>
                </div>
                <div className="flex items-center  pr-16 ">
                    
                    <span className="ml-14 hover:text-slate-300" onClick={open}>Write</span>
                        {!menu &&
                            <div className="absolute mt-40 ml-10 flex flex-col">
                                <span className="pt-4">Дискорд</span>
                                <span className="pt-4">Вконтакте</span>
                                <span className="pt-4">Телеграмм</span>
                            </div>
                        }
                    <span  className="ml-14 hover:text-slate-300">Works</span>
                    <span className="ml-14 hover:text-slate-300">About</span>
                </div>
            </div>
        </div>
    );
  }
  
  export default Navbar;