
import First from "./pages/First";
import styles from "./styles/default.css"
function App() {


  return (
    <div className={styles}>
    <First/>
    </div>
    
  );
}

export default App;
