import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import styles from "../styles/default.css"
function First() {


  return (
    <div className={styles} >
    <div className="bg-gray-900 h-dvh">
    <Navbar> </Navbar>
    <Sidebar/>
    </div>
    </div>
    
  );
}

export default First;
