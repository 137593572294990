function Sidebar() {
    return (
      <div className="ml-20 mt-20">
        <div className="bg-gray-800 w-60 h-96 flex flex-col justify-center items-center">
          <span className="mt-5 text-slate-100 hover:text-slate-300 cursor-pointer">Sites</span>
          <span className="mt-5 text-slate-100 hover:text-slate-300 cursor-pointer">Skills</span>
          <span className="mt-5 text-slate-100 hover:text-slate-300 cursor-pointer">Repositories</span>
          <span className="mt-5 text-slate-100 hover:text-slate-300 cursor-pointer">Gamedev</span>
          <span className="mt-5 text-slate-100 hover:text-slate-300 cursor-pointer">Works</span>
          <span className="mt-5 text-slate-100 hover:text-slate-300 cursor-pointer">3d models</span>
          <span className="mt-5 text-slate-100 hover:text-slate-300 cursor-pointer">Other</span>
          <span className="mt-5 text-slate-100 hover:text-slate-300 cursor-pointer">Plugins java</span>
        </div>
      </div>
    );
  }
  
  export default Sidebar;
  